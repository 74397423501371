import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [2,3,6,7,8];

export const dictionary = {
		"/(app)": [10,[2]],
		"/(app)/no-org": [11,[2]],
		"/(app)/org/[orgId]": [12,[2,3]],
		"/(app)/org/[orgId]/explore": [~13,[2,3]],
		"/(app)/org/[orgId]/history": [~14,[2,3]],
		"/(app)/org/[orgId]/history/questions": [15,[2,3]],
		"/(app)/org/[orgId]/question/[questionId]": [~16,[2,3]],
		"/(app)/org/[orgId]/question/[questionId]/csv": [17,[2,3]],
		"/(app)/org/[orgId]/reports": [~18,[2,3]],
		"/(app)/org/[orgId]/reports/[reportId]": [~19,[2,3]],
		"/(app)/org/[orgId]/settings/activity": [~20,[2,3]],
		"/(app)/org/[orgId]/settings/ai": [~21,[2,3]],
		"/(app)/org/[orgId]/settings/apps": [~22,[2,3,4]],
		"/(app)/org/[orgId]/settings/apps/create": [~24,[2,3,4,5]],
		"/(app)/org/[orgId]/settings/apps/[appId]": [~23,[2,3,4]],
		"/(app)/org/[orgId]/settings/connections": [~25,[2,3]],
		"/(app)/org/[orgId]/settings/connections/create": [27,[2,3]],
		"/(app)/org/[orgId]/settings/connections/[connectionId]": [26,[2,3,6]],
		"/(app)/org/[orgId]/settings/general": [~28,[2,3]],
		"/(app)/org/[orgId]/settings/members": [29,[2,3]],
		"/(app)/org/[orgId]/settings/reports": [30,[2,3]],
		"/(app)/org/[orgId]/settings/slack": [31,[2,3]],
		"/(app)/org/[orgId]/settings/slack/permission_denied": [32,[2,3]],
		"/(app)/org/[orgId]/spacecamp": [~33,[2,3]],
		"/(app)/org/[orgId]/spacecamp/[spaceId]": [~34,[2,3]],
		"/(app)/org/[orgId]/spaces": [35,[2,3,7]],
		"/(app)/org/[orgId]/spaces/[spaceId]": [~36,[2,3,7,8]],
		"/(app)/org/[orgId]/spaces/[spaceId]/instructions": [37,[2,3,7,8]],
		"/(app)/org/[orgId]/spaces/[spaceId]/schema": [38,[2,3,7,8]],
		"/(app)/org/[orgId]/spaces/[spaceId]/settings": [39,[2,3,7,8]],
		"/(app)/org/[orgId]/spaces/[spaceId]/verified": [40,[2,3,7,8]],
		"/(app)/org/[orgId]/testing/example": [~41,[2,3]],
		"/(app)/org/[orgId]/testing/graphs": [~42,[2,3,9]],
		"/(app)/org/[orgId]/testing/graphs/[testSuiteId]": [43,[2,3,9]],
		"/(app)/org/[orgId]/themer": [44,[2,3]],
		"/(app)/org/[orgId]/verified": [~45,[2,3]],
		"/(app)/question/[questionId]": [~46,[2]],
		"/sign-in": [47],
		"/sign-up": [48]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';